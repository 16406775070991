import React from 'react';
import './App.css';
import DisplayWastewaterData from './components/DisplayWastewaterData';
import DisplaySewageData from './components/DisplaySewageData';
import ContaminationLevelDisplay from './components/ContaminationLevelDisplay';

const App: React.FC = () => {
  return (
    <div>
      <header className="title">
        ECOLENS DASHBOARD WELCOMES YOU
      </header>
        <div className="pitch">
          {/* Replace <body> with <div> */}
          Introducing Apaline EcoLens — a powerful compliance
           tracking dashboard designed to monitor regions 
          where sewage and industrial wastewater emissions
           exceed environmental standards.
          This tool empowers users, including environmental
           regulators, policymakers, and industry stakeholders, 
          to stay informed about areas of noncompliance, helping
           them assess the combined pressures on local 
          water systems and environmental health.
          By integrating datasets on sewage emissions and industrial
           wastewater, the platform offers real-time 
          insights into how these factors impact regional water quality.
          With its intuitive dashboard, users can visualize 
          noncompliant areas, track trends, and prioritize 
          corrective actions to protect vital water resources.
          
          {/* Displaying CSV data */}
          <DisplayWastewaterData />
          <DisplaySewageData />
          <ContaminationLevelDisplay />
      </div>
    </div>
  );
};

export default App;