import React, { useEffect, useState } from 'react';

interface SewageData {
  id: number;
  lastUpdated: string;
  facilityOwner: string;
  siteAddress: string;
  siteMunicipality: string;
  sector: string;
  district: string;
  contaminant: string;
  contaminantLimit: number;
  contaminantUnit: string;
  contaminantMinRecord: number;
  contaminantMaxRecord: number;
  exceedanceType: string;
  exceedanceStart: string;
  exceedanceEnd: string;
  exceedanceCount: number;
  limitFrequency: string;
  facilityAction: string;
  ministryAction: string;
}

const DisplaySewageData: React.FC = () => {
  const [data, setData] = useState<SewageData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  
  const [currentPage, setCurrentPage] = useState<number>(1);  // Track the current page
  const itemsPerPage = 10;  // Number of items to display per page

  // Fetch data from the sewage API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/sewage');
        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new Error('Invalid content type. Expected application/json.');
        }

        const result = await response.json();
        setData(result);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Calculate the number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Get the current page's data
  const currentData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Handle page change
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Pagination Controls
  const PaginationControls = () => (
    <div className="pagination">
      <button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
        Previous
      </button>
      <span> Page {currentPage} of {totalPages} </span>
      <button disabled={currentPage === totalPages} 
        onClick={() => handlePageChange(currentPage + 1)}>
        Next
      </button>
    </div>
  );

  return (
    <div>
      <h2 className='pitch'>Current Sewage Data</h2>
      
      {/* Top pagination controls */}
      <PaginationControls />

      <div className="table-container">
        <table className="styled-table">
          <thead>
            <tr>
              <th>Last Updated</th>
              <th>Facility Owner</th>
              <th>Site Address</th>
              <th>Site Municipality</th>
              <th>Sector</th>
              <th>District</th>
              <th>Contaminant</th>
              <th>Contaminant Limit</th>
              <th>Contaminant Unit</th>
              <th>Contaminant Min Record</th>
              <th>Contaminant Max Record</th>
              <th>Exceedance Type</th>
              <th>Exceedance Start</th>
              <th>Exceedance End</th>
              <th>Exceedance Count</th>
              <th>Limit Frequency</th>
              <th>Facility Action</th>
              <th>Ministry Action</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((entry, index) => (
              <tr key={index}>
                <td>{entry.lastUpdated}</td>
                <td>{entry.facilityOwner}</td>
                <td>{entry.siteAddress}</td>
                <td>{entry.siteMunicipality}</td>
                <td>{entry.sector}</td>
                <td>{entry.district}</td>
                <td>{entry.contaminant}</td>
                <td>{entry.contaminantLimit}</td>
                <td>{entry.contaminantUnit}</td>
                <td>{entry.contaminantMinRecord}</td>
                <td>{entry.contaminantMaxRecord}</td>
                <td>{entry.exceedanceType}</td>
                <td>{entry.exceedanceStart}</td>
                <td>{entry.exceedanceEnd}</td>
                <td>{entry.exceedanceCount}</td>
                <td>{entry.limitFrequency}</td>
                <td>{entry.facilityAction}</td>
                <td>{entry.ministryAction}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Bottom pagination controls */}
      <PaginationControls />
    </div>
  );
};

export default DisplaySewageData;
